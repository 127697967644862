import React from 'react'

const HeaderSlogan = (props) => {
  const { title, className } = props
  return(
    <div className={`header-slogan ${className}`}>
      {title}
    </div>
  )
}

export default HeaderSlogan
import * as React from "react"
import Layout from '../component/layout'
import Image from '../component/image'
import JourneyOmnidya from '../component/journeyOmnidya'
import Link from '../component/link'
import PageTitle from '../component/typography/pageTitle'
import HeaderSlogan from '../component/typography/headerSlogan'

const IndexPage = () => {
  return (
    <Layout page="header-bg" home>
      <div className="container-xxl">
        <div className="text-center mb-5">
          <PageTitle title="Insurance of the future" className="pt-5 pb-3" />
          <div className="pb-3">
            Get access to low rates, your personalized driver score, and nifty perks. We’re not your parent’s insurance company.
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col col-lg-8">
            <div className="row pb-5">
              <div className="col-xs col-sm-5">
                <div className="nav flex-column nav-pills custom-list font-book" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <Link className="nav-link active" id="quote-tab" data-toggle="pill" href="#quote" role="tab" aria-controls="quote" aria-selected="true">
                    <div className="icon mr-sm-2">
                      <Image src="user_shape.svg" className="svgIcon" />
                    </div>
                    Get Quote
                  </Link>
                  <Link className="nav-link" id="app-tab" data-toggle="pill" href="#app" role="tab" aria-controls="app" aria-selected="false">
                    <div className="icon mr-sm-2">
                      <Image src="download-2.svg" className="svgIcon" />
                    </div>
                    Download the App
                  </Link>
                  <Link className="nav-link" id="dashcam-tab" data-toggle="pill" href="#dashcam" role="tab" aria-controls="dashcam" aria-selected="false">
                    <div className="icon mr-sm-2">
                      <Image src="computer_shape.svg" className="svgIcon" />
                    </div>
                    Computer Vision based analytics
                  </Link>
                  <Link className="nav-link" id="adventures-tab" data-toggle="pill" href="#adventures" role="tab" aria-controls="adventures" aria-selected="false">
                    <div className="icon mr-sm-2">
                      <Image src="vehicle_shape.svg" className="svgIcon" />
                    </div>
                    Track your Adventures
                  </Link>
                </div>
              </div>
              <div className="col-xs col-sm-7">
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active text-center text-sm-left" id="quote" role="tabpanel" aria-labelledby="quote-tab">
                    <Image src="getQuote.svg" className="img-fluid" />
                    <div className="sub-Title my-3">Customized for you</div>
                    <p>
                      Tell us a bit about you and we’ll give you a personalized quote. 
                    </p>
                    <Link to="/contact" className="btn btn-gradient">
                      Tell Us
                    </Link>
                  </div>
                  <div className="tab-pane fade text-center text-sm-left" id="app" role="tabpanel" aria-labelledby="app-tab">
                    <Image src="downloadApp.svg" className="img-fluid" />
                    <div className="sub-Title my-3">Hit us up on the App Store or Google Play</div>
                    <div className="sub-Title text-center">Coming Soon</div>
                    {/* <p>Download our app for an easy way to manage your policy.</p>
                    <div>
                      <Image src="google-play-badge.png" fixed className="mr-sm-2" />
                      <Image src="app-store-badge-128-x-128.png" fixed />
                    </div> */}
                  </div>
                  <div className="tab-pane fade text-center text-sm-left" id="dashcam" role="tabpanel" aria-labelledby="dashcam-tab">
                    <Image src="freeDashcam.svg" className="img-fluid" />
                    <div className="sub-Title my-3">Did you know? </div>
                    <p>There’s a 0.45% chance that when you get into an accident, you’ll be a victim of fraud. Our dashcam helps take that down to 0%.</p>
                  </div>
                  <div className="tab-pane fade text-center text-sm-left" id="adventures" role="tabpanel" aria-labelledby="adventures-tab">
                    <Image src="drivingInsights.svg" className="img-fluid" />
                    <div className="sub-Title my-3">Get them receipts and rewards </div>
                    <p>Use your new dashcam to track your driving. Uh oh, crash? You got receipts. Good driving? You’ll pay less.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <div className="content-bg mb-5">
        <div className="container-xxl">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="row justify-content-center align-items-center justify-content-md-between text-center text-sm-left">
                <div className="col-5 col-sm-4 col-md-3 order-sm-last mb-5">
                  <Image src="ill-2.svg" class="img-fluid" />
                </div>
                <div className="col-xs col-sm col-md-6 order-sm-first mb-5">
                  <HeaderSlogan className="mb-3" title="We give you free tech" />
                  <div>Omnidya uses connected devices to help lower your premium. And guess what? We give these gadgets to you for free.</div>
                </div>
              </div>
              <div className="position-relative d-none d-sm-block">
                <Image src="combined-shape.svg" className="decorate-arrow-left" />
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row justify-content-center align-items-center justify-content-md-between text-center text-sm-left">
                <div className="col-5 col-sm-4 col-md-3 mb-5">
                  <Image src="ill-1.svg" class="img-fluid" />
                </div>
                <div className="col-xs col-sm col-md-6 mb-5">
                  <HeaderSlogan className="mb-3" title="Get access to your data" />
                  <div>Track your safe driving and get insights into your journey. Whether you’re going on a cross-country adventure, a trip to the grocery store, or over the river and through the woods, access your driving analytics at any time </div>
                </div>
              </div>
              <div className="position-relative d-none d-sm-block">
                <Image src="combined-shape.svg" className="decorate-arrow-right" />
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row justify-content-center align-items-center justify-content-md-between text-center text-sm-left">
                <div className="col-5 col-sm-4 col-md-3 order-sm-last mb-5">
                  <Image src="ill-3.svg" class="img-fluid" />
                </div>
                <div className="col-sm-5 order-sm-first mb-5">
                  <HeaderSlogan className="mb-3" title="Rank up" />
                  <div>Safe driving doesn’t just save you money, it puts you on the Omnidya leaderboard. Rank up to earn exclusive rewards. </div>
                </div>
              </div>
              <div className="position-relative d-none d-sm-block">
                <Image src="combined-shape.svg" className="decorate-arrow-left" />
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row justify-content-center align-items-center justify-content-md-between text-center text-sm-left mb-5">
                <div className="col-5 col-sm-4 col-md-3 mb-5">
                  <Image src="ill-4.svg" class="img-fluid" />
                </div>
                <div className="col-xs col-sm col-md-6">
                  <HeaderSlogan className="mb-3" title="Control your premium" />  
                  <div>Don’t pay more for others’ bad habits. Our premiums are behavior-based and custom to you.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JourneyOmnidya />
      <div className="app-content">
        <div className="container-xxl">
          <div className="row">
            <div className="col-xs col-sm-5 col-md-6 text-right d-none d-sm-block">
              <Image src="mobile_view.png" fixed />
            </div>
            <div className="col-xs col-sm col-md-6 text-center text-sm-left">
              <HeaderSlogan className="my-5" title="We're on the App Store and Google Play." />
              <div className="mb-5 hitUs">Hit us up. You know you want to. </div>
              <div className="position-relative">
                <div className="commingSoon-Wrapper position-absolute">
                  <div className="commingSoon d-inline-block">
                    <p>Coming Soon</p>
                    <p>Coming Soon</p>
                    <p>Coming Soon</p>
                  </div>
                  <div className="commingSoon d-inline-block">
                    <p>Coming Soon</p>
                    <p>Coming Soon</p>
                    <p>Coming Soon</p>
                  </div>
                  <div className="commingSoon d-inline-block">
                    <p>Coming Soon</p>
                    <p>Coming Soon</p>
                    <p>Coming Soon</p>
                  </div>
                </div>
                <Image src="google-play-badge.png" fixed className="mr-2" />
                <Image src="app-store-badge-128-x-128.png" fixed />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl">
        <div className="world-map">
          <div className="text-center mb-5">
            <div className="header-slogan pt-5 pb-3">
              Where in the world is Omnidya?
            </div>
            <div className="pb-3 grey2">
              Check where we are and will be soon
            </div>
            <div className="text-center p-5">
              <Image src="usa-map.svg" className="img-fluid" />
              {/* <Link to="comingsoon">
                <Image src="map.png" className="img-fluid" />
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
